@use "./variables" as *;

.viz-icons {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top right;
}
.icon-advancedgrid-light {background-image: url("#{$assets-images}/viz-icons/ag-grid-light.svg");}
.icon-advancedgrid-dark {background-image: url("#{$assets-images}/viz-icons/ag-grid-dark.svg");}

.icon-donut-light {background-image: url("#{$assets-images}/viz-icons/donut-light.svg");}
.icon-donut-dark {background-image: url("#{$assets-images}/viz-icons/donut-dark.svg");}

.icon-horizontal-bar-light {background-image: url("#{$assets-images}/viz-icons/hbar-light.svg");}
.icon-horizontal-bar-dark {background-image: url("#{$assets-images}/viz-icons/hbar-dark.svg");}

.icon-horizontal-stacked-light {background-image: url("#{$assets-images}/viz-icons/hbar-stacked-light.svg");}
.icon-horizontal-stacked-dark {background-image: url("#{$assets-images}/viz-icons/hbar-stacked-dark.svg");}

.icon-line-chart-light {background-image: url("#{$assets-images}/viz-icons/line-light.svg");}
.icon-line-chart-dark {background-image: url("#{$assets-images}/viz-icons/line-dark.svg");}

.icon-vertical-mirrored-light {background-image: url("#{$assets-images}/viz-icons/mirror-bar-light.svg");}
.icon-vertical-mirrored-dark {background-image: url("#{$assets-images}/viz-icons/mirror-bar-dark.svg");}

.icon-pie-chart-light {background-image: url("#{$assets-images}/viz-icons/pie-light.svg");}
.icon-pie-chart-dark {background-image: url("#{$assets-images}/viz-icons/pie-dark.svg");}

.icon-simple-grid-light {background-image: url("#{$assets-images}/viz-icons/simple-grid-light.svg");}
.icon-simple-grid-dark {background-image: url("#{$assets-images}/viz-icons/simple-grid-dark.svg");}

.icon-summary-widget-light {background-image: url("#{$assets-images}/viz-icons/summary-light.svg");}
.icon-summary-widget-dark {background-image: url("#{$assets-images}/viz-icons/summary-dark.svg");}

.icon-vertical-bar-light {background-image: url("#{$assets-images}/viz-icons/vbar-light.svg");}
.icon-vertical-bar-dark {background-image: url("#{$assets-images}/viz-icons/vbar-dark.svg");}

.icon-vertical-stacked-light {background-image: url("#{$assets-images}/viz-icons/vbar-stacked-light.svg");}
.icon-vertical-stacked-dark {background-image: url("#{$assets-images}/viz-icons/vbar-stacked-dark.svg");}
